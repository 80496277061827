<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Toast',

  components: {
    Action: () => import('@/components/general/Action')
  },

  data () {
    return {
      isActive: false,
      timer: null,
      timeout: 6000
    }
  },

  computed: {
    ...mapState([
      'feedback'
    ]),

    random () {
      return this.feedback.random
    },

    color () {
      return this.feedback.textColor
    },

    background () {
      return this.feedback.backgroundColor
    }
  },

  watch: {
    isActive () {
      this.startTimer()
    },

    random () {
      if (!this.isActive) {
        this.activeFeedback = this.feedback
      }
      this.isActive = false
      this.$nextTick(() => {
        this.isActive = true
      })
    }
  },

  methods: {
    ...mapActions([
      'setFeedback'
    ]),

    startTimer (timeout = this.timeout) {
      if (this.$refs.toast) {
        this.$refs.toast.classList.add('untouched')
      }

      clearTimeout(this.timer)
      if (this.isActive) {
        this.timer = setTimeout(() => {
          this.isActive = false
        }, timeout)
      }
    },

    close () {
      this.isActive = false
    },

    stopTimer () {
      this.$refs.toast.classList.add('touched')
      this.$refs.toast.classList.remove('untouched')
      clearTimeout(this.timer)
    }
  }
}
</script>
<template>
  <transition name="toast">
    <div
      v-if="isActive"
      ref="toast"
      class="toast"
      :style="{ background }"
      @mouseover="stopTimer"
      @mouseleave="startTimer(1000)"
    >
      <span
        v-if="timeout"
        class="toast-progress"
      />

      <h5
        v-if="feedback.title"
        class="toast-title"
      >
        {{ feedback.title }}
      </h5>

      <div
        v-if="feedback.message"
        class="toast-message"
      >
        <p
          :style="{ color }"
          v-html="feedback.message"
        />
      </div>

      <Action
        class="btn-close"
        :style="{ color }"
        type="button"
        icon="close"
        icon-size="medium"
        @click="close()"
      />
    </div>
  </transition>
</template>
<style src="@/assets/styles/themes/default/toast.css"></style>
